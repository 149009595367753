.tab-edit-modal .mt-5 {
  margin-top: 5px;
}
.tab-edit-modal .mt-10 {
  margin-top: 10px;
}
.tab-edit-modal .img-edit-wrapper .img-edit-content {
  display: flex;
}
.tab-edit-modal .img-edit-wrapper .img-edit-content .img-title {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 5px;
}
.tab-edit-modal .img-edit-wrapper .img-tip {
  font-size: 12px;
  color: #999999;
}