.list-wrap {
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}
.list-wrap:hover {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-color: none;
}
.list-wrap:last-child {
  border-bottom: none;
}
.list-wrap .list-item {
  display: flex;
}
.list-wrap .list-item .list-item-title-wrapper {
  background-color: #fafafa;
  min-width: 120px;
  padding: 0px 12px;
  border-right: 1px solid #f0f0f0;
}
.list-wrap .list-item .list-item__label {
  height: 100%;
  padding: 12px;
  padding-left: 0;
  text-align: right;
}
.list-wrap .list-item .list-item__content {
  padding: 12px;
  padding-right: 0;
  text-align: left;
  min-width: 240px;
}