.mt-15 {
  margin-top: 15px;
}

.theme-btn {
  color: #2c68ff;
  border-color: #2c68ff;
  border-radius: 4px;
}

.setting-theme-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0px 24px 0;
  height: 100%;
  padding-bottom: 100px;
}
.setting-theme-wrapper .mt-20 {
  margin-top: 20px;
}
.setting-theme-wrapper .mt-40 {
  margin-top: 40px;
}
.setting-theme-wrapper .mb-16 {
  margin-bottom: 16px;
}
.setting-theme-wrapper .mb-25 {
  margin-bottom: 25px;
}
.setting-theme-wrapper .mr-2 {
  margin-right: 2px;
}
.setting-theme-wrapper .setting-theme-left {
  width: 541px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 0px 16px 24px;
  margin-right: 16px;
}
.setting-theme-wrapper .setting-theme-left .setting-theme-left-tip {
  font-size: 14px;
  line-height: 24px;
  color: #262626;
  margin-bottom: 24px;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper {
  display: flex;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview {
  display: flex;
  flex-wrap: wrap;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .preview-box {
  position: relative;
  width: 228px;
  height: 495px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #cdcdcd;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 7px 14px 0 rgba(13, 30, 47, 0.1);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .preview-box:first-child {
  margin-right: 35px;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewA .A-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 31px;
  right: -6px;
  background-color: #fff;
  font-size: 12px;
  height: 27px;
  width: 80px;
  border: 1px solid #fff;
  border-radius: 17.5px;
  font-size: 12px;
  transform: scale(0.65);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewA .A-num {
  position: absolute;
  bottom: 34.5px;
  left: 32px;
  font-size: 12px;
  transform: scale(0.9);
  font-weight: bold;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB {
  background-image: url("https://oss.elebuys.com/tmpdir/202109231131260007509686.png");
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-btn {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 29px;
  width: 161px;
  height: 22px;
  border-radius: 16px;
  border: 1px solid #fff;
  font-size: 12px;
  color: #fff;
  transform: scale(0.9);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-btn .left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-btn .right {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-num {
  position: absolute;
  width: 19px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  bottom: 42px;
  left: 41px;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  transform: scale(0.6);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-addr {
  position: absolute;
  bottom: 72px;
  left: 54px;
  font-size: 12px;
  transform: scale(0.8);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewB .B-price {
  position: absolute;
  bottom: 177px;
  left: 12px;
  font-weight: bold;
  font-size: 20px;
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewC {
  background-image: url("https://oss.elebuys.com/tmpdir/202109231439430007509724.png");
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewC .C-btn {
  position: absolute;
  left: -5px;
  bottom: 12px;
  height: 20px;
  width: 110%;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-radius: 22px;
  transform: scale(0.8);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewC .C-num1 {
  position: absolute;
  left: 8px;
  bottom: 32px;
  font-size: 12px;
  transform: scale(0.5);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewC .C-num2 {
  position: absolute;
  left: 13px;
  bottom: 32px;
  font-size: 12px;
  transform: scale(0.5);
}
.setting-theme-wrapper .setting-theme-left .setting-preview-wrapper .setting-preview .previewC .C-num3 {
  position: absolute;
  left: 20px;
  bottom: 78px;
  font-size: 14px;
  font-weight: 500;
  transform: scale(0.5);
}
.setting-theme-wrapper .setting-theme-right {
  flex: 1;
  min-width: 0;
  background-color: #fff;
  padding: 16px 0 0 24px;
  border-radius: 4px;
}
.setting-theme-wrapper .setting-theme-tip {
  display: flex;
  align-items: center;
}
.setting-theme-wrapper .setting-theme-tip .title {
  color: #262626;
  font-size: 16px;
  margin-right: 8px;
  font-weight: 500;
  line-height: 24px;
}
.setting-theme-wrapper .setting-theme-tip .tip {
  color: #787878;
  line-height: 24px;
  font-size: 12px;
}
.setting-theme-wrapper .setting-theme-display {
  display: flex;
  flex-wrap: wrap;
}
.setting-theme-wrapper .setting-theme-display .theme-item-active {
  border: 1px solid #2c68ff !important;
}
.setting-theme-wrapper .setting-theme-display .theme-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  box-sizing: border-box;
  width: 187px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  margin-bottom: 17px;
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-select-img {
  position: absolute;
  right: -0.5px;
  top: -0.5px;
  width: 40px;
  height: 40px;
  background-image: url("https://oss.elebuys.com/tmpdir/202207131429580000186023.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-image-wrap {
  position: relative;
  cursor: pointer;
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-image-wrap:hover .theme-image-preview {
  visibility: visible !important;
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-image-wrap .theme-image-preview {
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-image-wrap .theme-image-preview .image-preview {
  width: 52px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  color: #262626;
}
.setting-theme-wrapper .setting-theme-display .theme-item .theme-image {
  width: 185px;
  height: 128px;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}
.setting-theme-wrapper .setting-theme-display .theme-item .btn-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 8px 0 14px 14px;
  color: #787878;
  font-size: 14px;
}
.setting-theme-wrapper .setting-theme-display .theme-item .btn-wrapper .btn-wrapper-title {
  font-weight: 500;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}
.setting-theme-wrapper .setting-theme-display .theme-item .btn-wrapper .btn-right {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.setting-theme-wrapper .setting-theme-display .theme-item .btn-wrapper .btn-right .btn-theme-preview {
  margin-right: 10px;
  font-size: 24px;
  color: #c2c2c2;
  cursor: pointer;
}
.setting-theme-wrapper .setting-display-wrapper .title {
  color: #262626;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
}
.setting-theme-wrapper .setting-display-wrapper .setting-style-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.setting-theme-wrapper .setting-display-wrapper .setting-style-display .style-item {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  cursor: pointer;
  box-sizing: content-box;
  padding: 2px;
  border: 1px solid transparent;
}
.setting-theme-wrapper .setting-display-wrapper .setting-style-display .style-item.active {
  border: 1px solid #2c68ff;
}
.setting-theme-wrapper .setting-display-wrapper .setting-style-display .style-item div {
  box-sizing: border-box;
  flex: 1;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 4px;
}
.setting-theme-wrapper .setting-style-savebtn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  background-color: #fff;
  padding-right: 24px;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
}

.preview-modal-wrapper {
  position: relative;
  height: 600px;
}
.preview-modal-wrapper .preview-dot {
  bottom: -30px;
}
.preview-modal-wrapper .preview-dot li {
  background-color: #dddddd;
}
.preview-modal-wrapper .preview-dot li.slick-active button {
  background-color: #a9a9a9 !important;
}
.preview-modal-wrapper .preview-modal-content {
  display: flex !important;
  justify-content: center;
}
.preview-modal-wrapper .preview-modal-content .preview-modal-item {
  width: 258px;
  height: 560px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 23px;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}
.preview-modal-wrapper .preview-modal-content .preview-modal-item:last-child {
  margin-right: 0;
}
.preview-modal-wrapper .icon-fangxiang {
  font-size: 54px;
  color: #a9a9a9;
  cursor: pointer;
}
.preview-modal-wrapper .arrow-left {
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: -36px;
  z-index: 50;
  transform: rotate(180deg);
}
.preview-modal-wrapper .arrow-right {
  position: absolute;
  right: -5px;
  top: 50%;
  margin-top: -36px;
  z-index: 50;
}