@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.fx-produce-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;
}
.fx-produce-wrapper .fs-12 {
  font-size: 12px;
}
.fx-produce-wrapper .pl8 {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #4C4C4C;
  line-height: 20px;
}
.fx-produce-wrapper .fx-produce {
  height: 177px;
  border-radius: 4px;
  background-color: #ffffff;
}
.fx-produce-wrapper .fx-produce .fx-produce-title {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: 16px;
  height: 56px;
  font-weight: 500;
  border-bottom: 1px solid #eeeeee;
}

.fx-produce-content-wrapper {
  display: flex;
  padding: 0 24px;
  height: 120px;
  align-items: center;
  justify-content: space-between;
}
.fx-produce-content-wrapper .fx-produce-content-detail {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  cursor: pointer;
  width: 110px !important;
}
.fx-produce-content-wrapper .fx-produce-content-detail .detail-text {
  font-size: 32px;
  font-weight: bold;
  color: #4C4C4C;
  margin-bottom: 12px;
}