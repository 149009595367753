.ant-upload-drag-icon {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.file_container {
  display: flex;
  flex-wrap: wrap;
}
.file_container .info {
  display: flex;
  align-items: center;
}
.file_container .file_item {
  border: 1px solid #EEE;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 0 10px 10px 0;
  display: flex;
  align-items: center;
}
.file_container .file_item .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 2px;
}
.file_container .file_item .action {
  margin-left: 30px;
}
.file_container .file_item .file_name {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}

.file_extra {
  width: 100vw;
  font-size: 10px;
}