.order_detail .order_detail_tit {
  border-radius: 0px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.order_detail .order_detail_tit .order_detail_status {
  height: 22px;
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
  margin-bottom: 8px;
}
.order_detail .order_detail_tit .order_detail_tips {
  height: 22px;
  font-size: 14px !important;
  font-weight: 400;
  color: #787878;
  line-height: 22px;
  margin: 0 !important;
}
.order_detail .track .track_tit {
  color: #262626;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 24px;
}
.order_detail .track .track_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #262626;
  line-height: 24px;
}
.order_detail .track .track_con {
  color: #262626;
  padding-left: 14px;
}
.order_detail .track .track_con .ant-tabs-content-holder p {
  text-align: left;
  margin: 10px 0;
}
.order_detail .track .track-wrap {
  display: flex;
}
.order_detail .track .track-wrap-title {
  white-space: nowrap;
  font-weight: 500;
}
.order_detail .track .track-timeline {
  flex: 1;
  max-width: 700px;
  margin-top: 9px;
}
.order_detail .track .ant-timeline-item-last {
  padding-bottom: 0 !important;
}
.order_detail .track .ant-timeline-item-last > .ant-timeline-item-content {
  height: 16px !important;
}
.order_detail .order_infor .order_infor_tit {
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 18px;
}
.order_detail .order_infor .order_infor_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #4c4c4c;
  line-height: 24px;
}
.order_detail .order_infor .order_infor_con_wrap {
  display: flex;
  justify-content: space-between;
}
.order_detail .order_infor .order_infor_con_wrap .order_infor_con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 14px;
}
.order_detail .order_infor .order_infor_con_wrap .order_infor_con p {
  max-width: 360px;
  margin-bottom: 8px !important;
  font-size: 14px;
  color: #262626;
}
.order_detail .order_infor .order_infor_con_wrap .order_infor_con p > span:nth-of-type(1) {
  color: #787878;
  min-width: 70px;
}
.order_detail .order_infor .order_infor_con_wrap .order_infor_con p {
  margin: 0;
}
.order_detail .goods_infor .goods_infor_tit {
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 18px;
}
.order_detail .goods_infor .goods_infor_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #4c4c4c;
  line-height: 24px;
}
.order_detail .goods_infor .table {
  width: 100%;
  margin-bottom: 12px;
}
.order_detail .goods_infor .table .ant-table-thead .ant-table-cell {
  font-weight: 600 !important;
}
.order_detail .goods_infor .table .ant-table-tbody .ant-table-cell {
  color: #262626;
}
.order_detail .goods_infor .goods_infor_con {
  color: #262626;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_first {
  display: flex;
  padding-left: 14px;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_first p {
  margin-bottom: 2px;
  margin-right: 20px;
  display: flex;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_first p span {
  display: block;
  width: 72px;
  text-align: right;
  color: #787878;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 14px;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second div {
  display: flex;
  margin-right: 20px;
  margin-bottom: -8px;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second div span {
  display: block;
  width: 72px;
  text-align: right;
  color: #787878;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second > p {
  margin-bottom: 6px;
  margin-right: 20px;
  display: flex;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second > p span {
  display: block;
  width: 72px;
  text-align: right;
  color: #787878;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second > p:nth-last-child(1) {
  margin: 0;
}
.order_detail .goods_infor .goods_infor_con .goods_infor_con_second > p:nth-last-child(1) p {
  margin: 0;
}
.order_detail .ant-card {
  margin-bottom: 20px !important;
}

.order-details-container .ant-pro-layout-page-container-content {
  background: none !important;
}