.after_sale_manage_detail .order_detail {
  position: relative;
  border-radius: 0px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.after_sale_manage_detail .order_detail .order_detail_status {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
  margin-bottom: 8px;
}
.after_sale_manage_detail .order_detail .order_detail_tips {
  height: 22px;
  font-size: 14px !important;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin: 0 !important;
}
.after_sale_manage_detail .order_detail .immediate_refund {
  position: absolute;
  right: 60px;
  top: 28px;
}
.after_sale_manage_detail .track .track_tit {
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 24px;
}
.after_sale_manage_detail .track .track_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  line-height: 24px;
}
.after_sale_manage_detail .track .track_con {
  padding-left: 14px;
}
.after_sale_manage_detail .track .track_con .ant-tabs-content-holder p {
  text-align: left;
  margin: 10px 0;
}
.after_sale_manage_detail .track .track_con .ant-tabs-content-holder .track_con {
  margin: -14px 0 0 80px;
}
.after_sale_manage_detail .order_infor {
  min-width: 900px;
}
.after_sale_manage_detail .order_infor .order_infor_tit {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 18px;
}
.after_sale_manage_detail .order_infor .order_infor_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  line-height: 24px;
}
.after_sale_manage_detail .order_infor .order_infor_con_wrap {
  display: flex;
  justify-content: space-between;
}
.after_sale_manage_detail .order_infor .order_infor_con_wrap .order_infor_con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 0 14px;
}
.after_sale_manage_detail .order_infor .order_infor_con_wrap .order_infor_con p {
  margin-bottom: 8px;
  font-size: 14px;
  color: #262626;
  line-height: 22px;
}
.after_sale_manage_detail .order_infor .order_infor_con_wrap .order_infor_con p span {
  color: #787878;
}
.after_sale_manage_detail .order_infor .order_infor_con_desc {
  margin-bottom: 12px;
  padding: 0 14px;
  height: 22px;
  font-size: 14px;
  color: #262626;
  line-height: 22px;
}
.after_sale_manage_detail .order_infor .order_infor_con_desc span {
  color: #787878;
}
.after_sale_manage_detail .order_infor .order_infor_con_img {
  padding-left: 82px;
  overflow: hidden;
}
.after_sale_manage_detail .order_infor .order_infor_con_img .img {
  width: 90px;
  float: left;
  margin-right: 20px;
}
.after_sale_manage_detail .goods_infor .goods_infor_tit {
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 18px;
}
.after_sale_manage_detail .goods_infor .goods_infor_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  line-height: 24px;
}
.after_sale_manage_detail .goods_infor .table {
  width: 100%;
  margin-bottom: 12px;
}
.after_sale_manage_detail .goods_infor .table .ant-table-thead .ant-table-cell {
  font-weight: 600 !important;
}
.after_sale_manage_detail .goods_infor .table .ant-table-tbody .ant-table-cell {
  color: #262626;
}
.after_sale_manage_detail .after_sale_progress .after_sale_progress_tit {
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 18px;
}
.after_sale_manage_detail .after_sale_progress .after_sale_progress_tit h4 {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #262626;
  line-height: 24px;
}
.after_sale_manage_detail .after_sale_progress .sendInfo {
  min-width: 280px;
  max-width: 320px;
  border-radius: 8px;
  background: #fafafa;
  color: #000;
  padding: 8px;
}
.after_sale_manage_detail .after_sale_progress .sendInfo .second {
  display: flex;
  justify-content: space-between;
}
.after_sale_manage_detail .after_sale_progress .sendInfoAddress {
  min-width: 280px;
  max-width: 660px;
  min-height: 60px;
  max-height: 100px;
  border-radius: 8px;
  background: #fafafa;
  color: #000;
  padding: 8px;
}
.after_sale_manage_detail .after_sale_progress .sendInfoAddress .first {
  display: flex;
  justify-content: space-between;
}
.after_sale_manage_detail .after_sale_progress .sendInfoAddress .second {
  display: flex;
  justify-content: space-between;
}
.after_sale_manage_detail .after_sale_progress .sendInfoAddress .thirdly {
  margin-left: 98px;
}
.after_sale_manage_detail .after_sale_progress .ant-timeline-item-last {
  padding-bottom: 0 !important;
}
.after_sale_manage_detail .ant-card {
  margin-bottom: 20px;
}