.order-list-form-wrap {
  background: #ffffff;
  padding: 16px 24px;
  margin: 0 24px;
  margin-bottom: 24px;
  border-radius: 4px;
}
.order-list-form-wrap .ant-form-item {
  margin-bottom: 0;
}
.order-list-form-wrap .order-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}