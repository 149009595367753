@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.siteinterface-setting-header {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  font-weight: 500;
}

.site-setting-wrap .form-wrap {
  margin-top: 24px;
  justify-content: center;
}
.site-setting-wrap .form-wrap .checkable-tag-wrap {
  margin-top: 6px;
  display: flex;
  border-bottom: 1px solid #E8E8E8;
  padding-bottom: 24px;
}
.site-setting-wrap .form-wrap .checkable-tag-wrap .ant-tag-checkable:active {
  background-color: #2C68FF;
}
.site-setting-wrap .form-wrap .checkable-tag-wrap .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #2C68FF;
}
.site-setting-wrap .form-wrap .checkable-tag-wrap .ant-tag-checkable-checked {
  background-color: #2C68FF;
}
.site-setting-wrap .form-wrap .checkable-tag-wrap span {
  white-space: nowrap;
}
.site-setting-wrap .form-wrap .ant-tag-checkable {
  border-color: #d9d9d9;
  margin-bottom: 0;
}
.site-setting-wrap .name-suggest {
  position: relative;
  bottom: 20px;
  font-size: 14px;
  height: 24px;
  color: #d9d9d9;
}
.site-setting-wrap .name-suggest span {
  position: absolute;
  right: 0;
}

.site-setting {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.suggest-text-wrap {
  display: flex;
  flex-direction: column;
  color: #A9A9A9;
  font-size: 14px;
  margin-right: 24px;
}

.upload__label {
  display: flex;
  margin-top: 24px;
  margin-left: 10%;
}
.upload__label .upload-wrap {
  margin-left: 24px;
}

.copy-url-wrap {
  display: flex;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 12px;
}

.tagsList {
  margin-top: 6px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E8E8E8;
}
.tagsList .tagsName {
  margin-bottom: 14px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4A4A4A;
  line-height: 22px;
}
.tagsList .ant-checkbox-group-item {
  margin-right: 43px;
}

.checkable-tag--error {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 400;
}