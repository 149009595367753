.moblie-prview-container {
  margin-top: 10px;
  display: flex;
  height: 72vh;
  flex-direction: column;
}
.moblie-prview-container .banner {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}
.moblie-prview-container .banner img {
  width: 100%;
}
.moblie-prview-container .item {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 48.4%;
  box-sizing: border-box;
  margin-bottom: 8px;
}
.moblie-prview-container .item .item-con {
  box-shadow: 0px 1px 1.33333vw 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.moblie-prview-container .item img {
  border-radius: 4px;
  width: 100%;
}
.moblie-prview-container .item .info {
  padding: 10px;
}
.moblie-prview-container .item .info .name {
  max-width: 87px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.moblie-prview-container .item .info .price-color {
  color: orange;
}
.moblie-prview-container .con {
  flex: 1;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
}

.scroll-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
}