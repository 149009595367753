.saas-card-wrapper {
  background-color: #F8F8F8;
}
.saas-card-wrapper .ant-card-body {
  padding: 0;
}
.saas-card-wrapper .ant-tabs-nav {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 52px;
  padding: 0 24px 0;
  background-color: #fff;
  margin-bottom: 0;
}
.saas-card-wrapper .ant-tabs-content {
  padding-top: 60px;
}