.commodity-container {
  display: flex;
  align-items: center;
}
.commodity-container img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.commodity-container .info {
  margin-left: 10px;
}
.commodity-container .text-over {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}