@charset "UTF-8";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.35;
  font-size: 28px;
}

p {
  margin-bottom: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #3c3c3c;
}

html,
body {
  font-size: 14px;
  width: 100%;
}

input {
  outline: none;
}

input:focus {
  outline: none;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.fx-flexbox {
  width: auto;
  display: flex;
  align-items: center;
}
.fx-flexbox-flexwrap {
  flex-wrap: wrap;
}
.fx-flexbox-center {
  justify-content: center;
}
.fx-flexbox-between {
  justify-content: space-between;
}
.fx-flexbox-around {
  justify-content: space-around;
}
.fx-flexbox-right {
  justify-content: flex-end;
}
.fx-flexbox-align-initial {
  align-items: initial;
}
.fx-flexbox-align-end {
  align-items: flex-end;
}
.fx-flexbox-align-start {
  align-items: flex-start;
}
.fx-flexbox-content-around {
  align-content: space-around;
}
.fx-flexbox-vertical {
  flex-direction: column;
  height: 100%;
}
.fx-flexbox-vertical .fx-flexbox-item {
  width: 100%;
}
.fx-flexbox-item {
  flex: 1;
}
.fx-flexbox-item-start {
  align-self: flex-start;
  justify-self: flex-end;
}
.fx-flexbox-item-center {
  align-self: center;
}
.fx-flexbox-item-end {
  align-self: flex-end;
}

.pro-layout-content {
  position: relative;
}

.ant-pro-layout-main {
  padding-top: 0 !important;
}

.pro-layout-wrap .ant-menu-item {
  transition: none !important;
}
.pro-layout-wrap .ant-menu-root > .ant-menu-item {
  font-weight: 500 !important;
}
.pro-layout-wrap .ant-menu-root > .ant-menu-item-selected {
  width: 176px !important;
  background: #2C68FF !important;
  box-shadow: 0px 2px 6px 0px rgba(137, 169, 250, 0.52);
  border-radius: 4px;
  color: #ffffff !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  padding-left: 16px !important;
}
.pro-layout-wrap .ant-menu-sub .ant-menu-item-selected {
  width: 176px !important;
  background: #2C68FF !important;
  box-shadow: 0px 2px 6px 0px rgba(137, 169, 250, 0.52);
  border-radius: 4px;
  color: #ffffff !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.pro-layout-wrap .ant-menu-sub .ant-menu-item-selected .ant-menu-title-content {
  position: relative;
  left: -8px;
}
.pro-layout-wrap .ant-menu-submenu .ant-menu-submenu-title {
  font-weight: 500 !important;
}
.pro-layout-wrap .ant-menu-item::after {
  display: none !important;
}
.pro-layout-wrap .ant-menu-sub.ant-menu-inline {
  background-color: #fff !important;
}