.rechange {
    .ww-search {
        width: 100%;
        background-color: #ffffff;
        padding: 20px;
        border-radius: 4px;
        .ww-search-flex {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          .ww-search-range-input {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
      }
  }
