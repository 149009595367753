.commodity-new-group {
  flex: 1;
  height: 100vh;
  margin: 20px;
}
.commodity-new-group .filtration-con {
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  padding-top: 10px;
}
.commodity-new-group .table-con {
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
}
.commodity-new-group .btn-con {
  border-radius: 4px;
  background-color: #fff;
  padding: 8px 20px;
  border-bottom: 1px solid #EEEEEE;
}