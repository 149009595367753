@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.alter-price-market-modal-components .alter-price-content {
  width: 804px;
  overflow: hidden;
}
.alter-price-market-modal-components .alter-price-content .alter-price-content-title-wrap {
  height: 22px;
  margin-bottom: 12px;
}
.alter-price-market-modal-components .alter-price-content .alter-price-content-title-wrap .alter-price-content-title {
  float: left;
  width: 70px;
  height: 22px;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #4c4c4c;
  line-height: 22px;
}
.alter-price-market-modal-components .alter-price-content .alter-price-content-title-wrap .alter-price-content-sub-title {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap {
  width: 806px !important;
  height: 250px;
  border: 1px solid #e2e2e2;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .radio-group-wrap {
  padding: 20px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .radio-content-left {
  height: 250px;
  float: left;
  border-right: 1px solid #e2e2e2;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .radio-content-left .radio-content-left-tit {
  width: 400px;
  height: 44px;
  background: #f0f0f0;
  font-size: 14px;
  font-weight: 500;
  color: #4c4c4c;
  text-align: center;
  line-height: 44px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .radio-content-right {
  height: 250px;
  float: left;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .radio-content-right .radio-content-right-tit {
  width: 401px;
  height: 44px;
  background: #f0f0f0;
  font-size: 14px;
  font-weight: 500;
  color: #4c4c4c;
  text-align: center;
  line-height: 44px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .alter-price-input-number {
  width: 100px;
  height: 28px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .alter-price-input-number .ant-input-number-input {
  height: 25px;
  line-height: 28px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .ml-12 {
  margin-left: 12px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .alter-price-radio-tips {
  margin-left: 14px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #2C68FF;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.alter-price-market-modal-components .alter-price-content .radio-content-wrap .ant-form-item {
  margin-bottom: 0;
  margin-top: -4px;
  float: left;
}
.alter-price-market-modal-components .alter-price-content .alter-price-submit-wrap {
  margin: 20px 0 6px 100px;
}
.alter-price-market-modal-components .ant-modal-body {
  padding: 16px 20px;
}
.alter-price-market-modal-components .alter-price-item-radio-wrap {
  margin-bottom: 10px;
}
.alter-price-market-modal-components .alter-price-item-radio-wrap .radio span:nth-child(2) {
  display: flex;
}