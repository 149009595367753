@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
/*topbar的字体*/
.user-route-layout .ant-pro-layout-page-container-content {
  padding: 0 !important;
  background-color: #F8F8F8 !important;
}

.user {
  display: flex;
}
.user .user-nav-menu {
  min-height: 80vh;
  background-color: #ffffff;
}
.user .user-nav-menu .ant-menu {
  border-right: none;
}
.user .tree-table {
  display: flex;
  margin-left: 12px;
  flex: 1;
}
.user .tree-table .user-table-form {
  flex: 1;
  margin-left: 12px;
  background-color: #ffffff;
  border-radius: 2px;
}
.user .tree-table .user-table-form .ant-table-tbody > tr > td {
  text-align: center;
}
.user .tree-table .user-table-form .ant-table-thead > tr > th {
  text-align: center;
}
.user .tree-table .user-table-form .search-form {
  color: red;
  margin: 12px;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
}
.user .tree-table .user-table-form .search-form .ant-form {
  flex: 1;
  margin-right: -12px;
}
.user .tree-table .user-table-form .search-form .btn-wrap {
  margin-right: 12px;
  min-width: 350px;
}
.user .tree-table .user-table-form .search-form .btn-wrap .btn {
  margin-left: 12px;
}
.user .tree-table .user-table-form .treeNodeData-list {
  line-height: 12px;
  margin: 0 24px;
  margin-top: 12px;
  overflow: auto;
}
.user .tree-table .user-table-form .table-wrap {
  margin: 14px;
}

.detail-btn {
  cursor: pointer;
  color: #2C68FF;
}