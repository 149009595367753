.addBulk .title {
  font-size: 16px;
  font-weight: 600;
}
.addBulk .content p {
  text-align: left;
  margin-left: 40px;
}
.addBulk .lastCon {
  margin-left: 46px !important;
}
.addBulk .ant-btn {
  margin-top: 6px;
  margin-left: 16px;
}

.userManagement_userInfo {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.userManagement_userInfo .user-table-form {
  flex: 1;
  width: 100%;
  padding: 16px 0;
  margin-left: 20px;
  border-radius: 4px;
  background: #fff;
}
.userManagement_userInfo .user-table-form .search-form {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0 !important;
  padding-bottom: 10px !important;
}
.userManagement_userInfo .status-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.table-wrap {
  padding: 0 20px 20px 20px;
}