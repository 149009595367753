@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.ant-table-sticky-scroll {
  display: none;
}

.status-item-wrapper {
  display: flex;
  align-items: center;
}
.status-item-wrapper .status-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
}

.shop-image-wrap {
  background-color: #ffffff;
  border-radius: 2px;
  width: 46px;
  height: 46px;
}
.shop-image-wrap img {
  width: 46px;
  height: 46px;
  border-radius: 2px;
}

.shop-wrap {
  display: flex;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 17px;
}

.text {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  cursor: pointer;
}

.action-btn {
  color: #2C68FF;
  display: flex;
  align-items: center;
}
.action-btn span {
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  white-space: nowrap;
  padding-right: 8px;
  padding-left: 8px;
  border-right: 1px solid #E9E9E9;
}
.action-btn span:first-child {
  padding-left: 0;
}
.action-btn span:last-child {
  border: none;
  padding-right: 0;
}

.plus-wrap {
  width: 60px;
}
.plus-wrap img {
  width: 100%;
}

.price {
  color: #D1302C;
  font-weight: 500;
  font-size: 12px;
}

.omit {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #D1302C;
  text-align: center;
  margin: 4px;
}

.price-btn {
  display: inline-block;
  line-height: 22px;
  border-radius: 11px;
  border: 1px solid #D1302C;
  color: #D1302C;
  cursor: pointer;
}
.price-btn span {
  padding: 0 4px;
  white-space: nowrap;
}

.level-price {
  color: #666666;
}

.intor {
  margin-top: 20px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.sort .sort_judge {
  display: inline-block;
}
.sort .sort_judge:hover {
  cursor: pointer;
}

.mine-commodiy-table {
  background: #fff;
}

.table-wrap-container {
  padding: 20px 20px 20px 20px;
  padding-top: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}