p {
  margin: 0;
}

.list-container {
  margin-top: 20px;
  overflow: scroll;
  overflow-x: auto;
}
.list-container .item {
  display: flex;
  padding: 16px;
  border-radius: 4px;
}
.list-container .item:hover {
  background: #FFFFFF;
  box-shadow: 0px 6px 10px 0px rgba(200, 201, 204, 0.5);
}
.list-container .item-l {
  display: flex;
  align-items: center;
}
.list-container .item-l .icon img {
  width: 16px;
  height: 16px;
}
.list-container .item-l .info {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  margin-left: 8px;
}
.list-container .item-l .info .name {
  color: #4C4C4C;
  width: 384px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-container .item-l .info .img {
  width: 72px;
  height: 72px;
  border-radius: 4px;
}
.list-container .item-l .info .good {
  margin-left: 8px;
}
.list-container .item-l .info .sales-price, .list-container .item-l .info .market-price {
  color: #787878;
}
.list-container .item-r {
  display: flex;
  align-items: center;
}

.drawer-container .ant-drawer-body {
  display: flex;
  flex-direction: column;
}