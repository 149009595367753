.ant-card-bordered {
  border: none;
}

.after_sale_order {
  color: #262626;
  background-color: #f8f8f8;
}
.after_sale_order .after_sale_order_tabs {
  margin-bottom: 20px;
  background-color: #fff;
}
.after_sale_order .after_sale_order_tabs .ant-tabs-nav-wrap {
  height: 52px !important;
  padding-left: 40px;
}
.after_sale_order .ant-tabs-nav {
  margin-bottom: 0;
}
.after_sale_order .table_vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_sale_order .table_vertical_left {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  color: #4C4C4C !important;
}
.after_sale_order .table_vertical_left .ant-statistic-content-value-int {
  color: #4C4C4C !important;
}
.after_sale_order .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .after_sale_form {
  background: #fff;
  padding: 20px 48px;
  margin: 0 24px 24px;
}
.after_sale_order .order_table_wrap {
  margin: 0 24px 24px;
  padding: 16px 24px 24px;
  background-color: #fff;
  border-radius: 4px;
}
.after_sale_order .order_table_header {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: #fff;
  font-weight: 500;
  color: rgba(38, 38, 38, 0.85);
  background: #FAFAFA;
  height: 54px;
  margin-bottom: 8px;
  border-radius: 4px 4px 0px 0px;
}
.after_sale_order .row-con .ant-col {
  height: 96px !important;
}
.after_sale_order .order_table_div {
  margin-bottom: 13px;
  background-color: #fff;
}
.after_sale_order .order_table_div .order_table_list_header {
  color: #262626;
  padding: 10px 0 10px 10px;
  margin: 0 !important;
  background: #f8f8f8;
}
.after_sale_order .order_table_div .order_table_list_header .header_time {
  color: #999999;
}
.after_sale_order .order_table_div .contact_me {
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  color: #48a9f7;
}
.after_sale_order .order_table_div .order_table_box {
  text-align: center;
  border: 1px solid #eeeeee;
  border-top: none;
}
.after_sale_order .order_table_div .order_table_box .order_table_list {
  text-align: center;
  padding: 28px 0 28px 0;
  margin: 0 !important;
}
.after_sale_order .order_table_div .order_table_box .order_table_list .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .order_table_div .order_list_state .table_vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_sale_order .order_table_div .order_list_state .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .order_table_div .order_list_state span {
  font-size: 12px;
  color: #999999;
}
.after_sale_order .order_table_div .order_list_state .order_state {
  font-size: 13px;
  color: #c91623;
  font-weight: 500;
}
.after_sale_order .order_table_div .order_list_price .table_vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_sale_order .order_table_div .order_list_price .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .order_table_div .order_list_price .final_price {
  font-size: 16px;
  font-weight: 500px;
}
.after_sale_order .order_table_div .order_once_source {
  font-weight: 500;
}
.after_sale_order .order_table_div .order_once_source .table_vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_sale_order .order_table_div .order_once_source .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .order_table_div .oder_btn_list .table_vertical_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.after_sale_order .order_table_div .oder_btn_list .table_border {
  border-right: none;
  border-bottom: 1px solid #eeeeee;
}
.after_sale_order .goods_name_ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.after_sale_order .pagin_ation {
  text-align: right;
  margin: 30px 0 !important;
}