.addBulk .title {
  font-size: 16px;
  font-weight: 600;
}
.addBulk .content p {
  text-align: left;
  margin-left: 40px;
}
.addBulk .content p span {
  color: red;
}
.addBulk .lastCon {
  margin-left: 46px !important;
}
.addBulk .ant-btn {
  margin-top: 6px;
  margin-left: 16px;
}