.color-select-wrapper {
  display: inline-flex;
  align-items: center;
}
.color-select-wrapper .select-reset {
  margin-left: 15px;
  color: #2C68FF;
  cursor: pointer;
}
.color-select-wrapper .select-btn {
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  width: 60px;
  height: 30px;
  outline: none;
  padding: 3px;
  cursor: pointer;
}
.color-select-wrapper .select-btn .select-btn-bg {
  box-sizing: border-box;
  height: 100%;
}

.color-select-picker {
  margin: -20px !important;
}