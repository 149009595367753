@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
/*topbar的字体*/
.mr16 {
  margin-right: 16px !important;
}

.fx-template-wrap {
  width: 100%;
  padding-bottom: 20px;
}
.fx-template-wrap .fx-template-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 48px;
  background-color: #fff;
  height: 99px;
  margin-bottom: 24px;
}
.fx-template-wrap .fx-template-header .header-left {
  display: flex;
  align-items: center;
}
.fx-template-wrap .fx-template-header .header-left .header-left-img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-right: 16px;
}
.fx-template-wrap .fx-template-header .header-left .text1 {
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: #4C4C4C;
  line-height: 33px;
  margin-bottom: 5px;
}
.fx-template-wrap .fx-template-header .header-left .text2 {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #787878;
  line-height: 20px;
}
.fx-template-wrap .fx-display-wrap {
  box-sizing: border-box;
  margin: 0 24px;
}
.fx-template-wrap .fx-template--edit {
  width: 288px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 4px;
}
.fx-template-wrap .fx-template--edit header {
  display: flex;
  margin: 27px 24px 0 24px;
  justify-content: center;
  position: relative;
}
.fx-template-wrap .fx-template--edit header .edit-out-lined {
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
}
.fx-template-wrap .fx-template--edit .fx-header-wrap {
  max-width: 128px;
  text-align: center;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  margin-right: 9px;
}
.fx-template-wrap .fx-template--edit section {
  padding: 32px 24px;
  overflow: auto;
  border-bottom: none;
}
.fx-template-wrap .fx-template--edit section .fx-button-group {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
}
.fx-template-wrap .fx-template--edit section .fx-button-group button {
  line-height: 22px;
  border: 1px solid #2C68FF;
  color: #2C68FF;
}
.fx-template-wrap .fx-template--edit section .fx-button-group button:first-child {
  margin-right: 24px;
}
.fx-template-wrap .fx-template--edit section .fx-button-group .ant-btn-primary {
  color: white;
}
.fx-template-wrap .fx-template--edit section .fx-screeshot-wrap {
  margin-top: 17px;
  text-align: center;
  position: relative;
}
.fx-template-wrap .fx-template--edit section .fx-screeshot-wrap .releasedPageName {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 12px;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fx-template-wrap .fx-template--edit section .fx-screeshot-wrap .fx-screestop--img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 10px 10px 0px rgba(4, 18, 46, 0.05);
}
.fx-template-wrap .fx-template--edit section .fx-screeshot-wrap .fx-screeshot--img {
  width: 100%;
  box-shadow: 0px 10px 10px 0px rgba(4, 18, 46, 0.05);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.fx-template-wrap .fx-template--edit section .fx-entrance-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.fx-template-wrap .fx-template--edit section .fx-entrance-wrap .fx-entrance--circle {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.fx-template-wrap .fx-template--edit section .fx-entrance-wrap .fx-entrance--circle img {
  width: 30px;
}