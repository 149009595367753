.setting-tab-wrapper {
  display: flex;
  margin-bottom: 16px;
  background-color: #F8F8F8;
  margin: 0px 24px 0;
  padding-bottom: 100px;
}
.setting-tab-wrapper .op5 {
  opacity: 0.5 !important;
}
.setting-tab-wrapper .setting-tab-left {
  width: 338px;
  margin-right: 16px;
  background-color: #fff;
  padding: 16px 0 0 24px;
  border-radius: 4px;
}
.setting-tab-wrapper .setting-tab-left .setting-tab-left-title {
  color: #262626;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}
.setting-tab-wrapper .setting-tab-left .tab-style-display {
  position: relative;
  display: flex;
  align-items: center;
  color: #7f7f7f;
  width: 294px;
  height: 50px;
  box-shadow: 0px 1px 5px 0px rgba(207, 207, 207, 0.5);
  border-radius: 2px;
  margin-bottom: 25px;
  background: transparent;
  z-index: 1;
  overflow: hidden;
  background: rgba(152, 151, 151, 0.01);
}
.setting-tab-wrapper .setting-tab-left .tab-style-display::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(15px);
  background: var(--themeBgc);
  z-index: -1;
}
.setting-tab-wrapper .setting-tab-left .tab-style-display .img-style-display {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
.setting-tab-wrapper .setting-tab-left .tab-style-display .img-style-display .img-style-text {
  line-height: 14px;
  height: 14px;
  margin-top: 4px;
  font-size: 12px;
}
.setting-tab-wrapper .setting-tab-right {
  flex: 1;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-right-top {
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 4px;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-right-bottom {
  padding: 21px 24px 40px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 11px;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-tip {
  display: flex;
  align-items: center;
  color: #ffab00;
  font-size: 12px;
  line-height: 24px;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-tip .setting-tab-tip-title {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 8px;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-title {
  color: #262626;
  font-size: 14px;
  margin-top: 24px;
}
.setting-tab-wrapper .setting-tab-right .setting-table-title {
  color: #262626;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}
.setting-tab-wrapper .setting-tab-right .setting-tab-title-wrapper {
  display: flex;
  align-items: center;
}
.setting-tab-wrapper .setting-tab-right .table-style {
  font-size: 14px;
  color: #4c4c4c;
}
.setting-tab-wrapper .setting-tab-right .tabs-ant-btn-wrapper .ant-btn {
  font-size: 14px;
  line-height: 14px;
  height: 16px;
  padding: 0;
  padding-right: 8px;
  border-right: 1px solid #e9e9e9;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper {
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-display {
  position: relative;
  display: flex;
  align-items: center;
  color: #7f7f7f;
  width: 375px;
  height: 57px;
  box-shadow: 0px 1px 5px 0px rgba(207, 207, 207, 0.5);
  border-radius: 2px;
  margin: 30px 0 30px;
  background: transparent;
  z-index: 1;
  overflow: hidden;
  background: rgba(152, 151, 151, 0.01);
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-display::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(15px);
  background: var(--themeBgc);
  z-index: -1;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-display .img-style-display {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-display .img-style-display .img-style-text {
  line-height: 14px;
  height: 14px;
  margin-top: 4px;
  font-size: 12px;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-set {
  display: flex;
  align-items: center;
  height: 24px;
  margin-top: 16px;
  font-size: #262626;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-set .tab-style-set-title {
  color: #262626;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
}
.setting-tab-wrapper .setting-tab-right .tab-style-wrapper .tab-style-item .tab-style-set .color-code {
  display: inline-block;
  width: 120px;
  text-align: center;
  color: #4c4c4c;
}
.setting-tab-wrapper .setting-tab-savebtn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
  height: 56px;
  background-color: #fff;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
}