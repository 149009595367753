@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont2";
  /* Project id 3607457 */
  src: url("//at.alicdn.com/t/c/font_3607457_9mqom3a3lk.woff2?t=1661485031045") format("woff2"), url("//at.alicdn.com/t/c/font_3607457_9mqom3a3lk.woff?t=1661485031045") format("woff"), url("//at.alicdn.com/t/c/font_3607457_9mqom3a3lk.ttf?t=1661485031045") format("truetype");
}
.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yemianmingcheng:before {
  content: "";
}

.icon-gengxinshijian:before {
  content: "";
}

.icon-jiantou:before {
  content: "";
}

.icon-guanliweiyemian:before {
  content: "";
}

.icon-jichuzujian:before {
  content: "";
}

.icon-shezhi:before {
  content: "";
}

.icon-shangcheng:before {
  content: "";
}

.icon-shujugailan:before {
  content: "";
}

.icon-shangchengmoban:before {
  content: "";
}

.icon-yemianshezhi:before {
  content: "";
}

.icon-yingxiaomokuai:before {
  content: "";
}

.icon-yonghu:before {
  content: "";
}

.icon-zujianguanli:before {
  content: "";
}

.own-store {
  display: flex;
  margin: 0px 24px 0;
  box-sizing: border-box;
  padding-bottom: 32px;
}
.own-store .mr4 {
  margin-right: 4px;
}
.own-store .own-store-wrap {
  display: flex;
  width: 100%;
  height: 100%;
}
.own-store .store-left {
  width: 338px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-right: 16px;
  padding: 16px;
}
.own-store .store-left .store-left-title {
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  line-height: 24px;
  margin-bottom: 12px;
}
.own-store .store-left .model-iframe-header {
  width: 100%;
  height: 72px;
}
.own-store .store-left .page-view-model-iframe {
  position: relative;
  height: 567px;
  width: 305px;
  background-color: #fff;
}
.own-store .store-right {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 24px 53px;
  box-sizing: border-box;
}
.own-store .store-right .add-tiny-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.own-store .store-right .add-tiny-page-wrapper .add-tiny-left {
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #4C4C4C;
  line-height: 22px;
}
.own-store .store-right .store-table-wrapper {
  border-radius: 2px;
  height: calc(100% - 53px);
  box-sizing: border-box;
  overflow-y: auto;
}
.own-store .store-right .store-table-wrapper .store-table-item-active {
  background: rgba(166, 190, 250, 0.07) !important;
  border-radius: 2px !important;
  border: 1px solid #2C68FF !important;
}
.own-store .store-right .store-table-wrapper .store-table-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  height: 84px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 24px;
  margin-bottom: 8px;
  border-radius: 2px;
}
.own-store .store-right .store-table-wrapper .store-table-item:hover {
  background: rgba(44, 104, 255, 0.07);
  border-radius: 2px;
  border: 1px solid #2C68FF;
}
.own-store .store-right .store-table-wrapper .store-table-item .table-item-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.own-store .store-right .store-table-wrapper .store-table-item .table-item-top .top-wrap {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
}
.own-store .store-right .store-table-wrapper .store-table-item .table-item-bottom {
  display: flex;
  color: #787878;
  font-size: 12px;
  line-height: 22px;
}
.own-store .store-right .store-table-wrapper .store-table-item .table-item-bottom .item-line {
  display: flex;
  align-items: center;
}
.own-store .store-right .store-table-wrapper .store-table-item .table-item-bottom .mr32 {
  margin-right: 32px;
}
.own-store .handle-wrapper {
  color: #2C68FF;
}
.own-store .handle-wrapper .ant-btn {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 16px;
  font-size: 14px;
  height: 16px;
}
.own-store .handle-wrapper .ant-btn:first-child {
  padding-left: 0;
}
.own-store .handle-wrapper .ant-btn:last-child {
  padding-right: 0;
  border: none;
}

.ant-modal-body .name-suggest {
  position: relative;
  bottom: 10px;
  font-size: 14px;
  height: 24px;
  color: #d9d9d9;
}
.ant-modal-body .name-suggest span {
  position: absolute;
  left: 32%;
}