.detail .ant-modal-content {
  width: auto;
  overflow: hidden;
}
.detail .ant-modal-content .tit {
  width: auto;
  overflow: hidden;
  margin-bottom: 20px;
}
.detail .ant-modal-content .tit .tit_con {
  float: left;
  width: 260px;
  height: 64px;
}
.detail .ant-modal-content .tit .tit_con p {
  text-align: left;
}
.detail .ant-modal-content .tit .tit_con .start {
  margin-left: 14px !important;
}
.detail .ant-modal-content .tit .tit_center {
  width: 272px;
  margin: 0 30px;
}
.detail .ant-modal-content .table_tit {
  display: flex;
  margin-bottom: 10px;
}
.detail .ant-modal-content .table_tit .creat_table h4 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 6px;
  padding: 0;
}
.detail .ant-modal-content .table_tit .creat_table p {
  color: #ff9933;
  margin: -22px 120px;
  padding: 0;
}
.detail .ant-modal-content .table_tit .ant-btn {
  margin-left: 166px;
}

.tit_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tit_modal h4 {
  font-size: 26px;
  font-weight: 500;
}
.tit_modal img {
  width: 100px;
}
.tit_modal div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}