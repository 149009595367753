.wang_wrap #wang_editor::after {
  float: right;
  color: #00000073;
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.wang_wrap .w-e-text-container p {
  text-align: left;
}
.wang_wrap .w-e-text-container b {
  font-weight: bolder !important;
}
.wang_wrap .w-e-text-container i {
  font-style: italic !important;
}
.wang_wrap .w-e-text-container ol {
  list-style: decimal !important;
}
.wang_wrap .w-e-text-container ul {
  list-style: disc !important;
}
.wang_wrap .save_wrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}