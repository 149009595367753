.commodity-new-group-create {
  flex: 1;
  margin: 20px;
  overflow: hidden;
}
.commodity-new-group-create .title {
  width: 64px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #4C4C4C;
  line-height: 24px;
}
.commodity-new-group-create .col-right {
  background: #fff;
  padding: 20px 0 0 20px;
  border-radius: 4px;
  height: 100%;
}
.commodity-new-group-create .col-left {
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: -Scroll;
  overflow-y: hidden;
}
.commodity-new-group-create .mobile-container {
  border: 1px solid #EEE;
  height: calc(100% - 40px);
  margin-top: 16px;
}
.commodity-new-group-create .form {
  margin-top: 30px;
}