.after_sale_form {
  border-radius: 4px;
  background: #fafafa;
  padding: 16px 24px !important;
  margin-bottom: 24px;
}
.after_sale_form .order-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.after_sale_form .order-btn .search-btn {
  cursor: pointer;
  color: #2C68FF;
  margin-left: 8px;
}