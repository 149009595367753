@charset "UTF-8";
/* border */
/* margin */
.fx-margin--1 {
  margin: 1px;
}

.fx-margin--2 {
  margin: 2px;
}

.fx-margin--3 {
  margin: 3px;
}

.fx-margin--4 {
  margin: 4px;
}

.fx-margin--5 {
  margin: 5px;
}

.fx-margin--6 {
  margin: 6px;
}

.fx-margin--7 {
  margin: 7px;
}

.fx-margin--8 {
  margin: 8px;
}

.fx-margin--9 {
  margin: 9px;
}

.fx-margin--10 {
  margin: 10px;
}

.fx-margin--11 {
  margin: 11px;
}

.fx-margin--12 {
  margin: 12px;
}

.fx-margin--13 {
  margin: 13px;
}

.fx-margin--14 {
  margin: 14px;
}

.fx-margin--15 {
  margin: 15px;
}

.fx-margin--16 {
  margin: 16px;
}

.fx-margin--17 {
  margin: 17px;
}

.fx-margin--18 {
  margin: 18px;
}

.fx-margin--19 {
  margin: 19px;
}

.fx-margin--20 {
  margin: 20px;
}

.fx-margin--21 {
  margin: 21px;
}

.fx-margin--22 {
  margin: 22px;
}

.fx-margin--23 {
  margin: 23px;
}

/* padding */
.fx-padding--1 {
  margin: 1px;
}

.fx-padding--2 {
  margin: 2px;
}

.fx-padding--3 {
  margin: 3px;
}

.fx-padding--4 {
  margin: 4px;
}

.fx-padding--5 {
  margin: 5px;
}

.fx-padding--6 {
  margin: 6px;
}

.fx-padding--7 {
  margin: 7px;
}

.fx-padding--8 {
  margin: 8px;
}

.fx-padding--9 {
  margin: 9px;
}

.fx-padding--10 {
  margin: 10px;
}

.fx-padding--11 {
  margin: 11px;
}

.fx-padding--12 {
  margin: 12px;
}

.fx-padding--13 {
  margin: 13px;
}

.fx-padding--14 {
  margin: 14px;
}

.fx-padding--15 {
  margin: 15px;
}

.fx-padding--16 {
  margin: 16px;
}

.fx-padding--17 {
  margin: 17px;
}

.fx-padding--18 {
  margin: 18px;
}

.fx-padding--19 {
  margin: 19px;
}

.fx-padding--20 {
  margin: 20px;
}

.fx-padding--21 {
  margin: 21px;
}

.fx-padding--22 {
  margin: 22px;
}

.fx-padding--23 {
  margin: 23px;
}

/* backgroud */
/* lineHieght */
/* font-size */
/* font-family */
/* color */
/* opcity,变量中以 100为1,以 0为0 */
/* border-radius */
/* font-weight */
/* width */
/* height*/
.pie-line-wrapper {
  height: 200px !important;
  top: 0;
}

.display-preview-modal .preview-main {
  background-color: #fff;
}
.display-preview-modal .preview-main .main-header {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #262626;
  line-height: 33px;
  margin-bottom: 24px;
}
.display-preview-modal .preview-main .main-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 72px;
  position: relative;
}
.display-preview-modal .preview-main .main-content .main-left {
  width: 218px;
  height: 468px;
  border-radius: 20px;
  border: 4px solid black;
  overflow-y: auto;
}
.display-preview-modal .preview-main .main-content .main-left .main-left-img {
  width: 100%;
}
.display-preview-modal .preview-main .main-content .main-left-tip {
  position: absolute;
  display: flex;
  align-items: center;
  left: 312px;
  top: 56px;
}
.display-preview-modal .preview-main .main-content .main-left-tip .main-left-tip-img {
  width: 15px;
  height: 62px;
}
.display-preview-modal .preview-main .main-content .main-left-tip .main-left-tip-title {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #A9A9A9;
  line-height: 20px;
  margin-left: 6px;
}
.display-preview-modal .preview-main .main-content .main-center {
  width: 61px;
  height: 14px;
}
.display-preview-modal .preview-main .main-content .main-right {
  position: relative;
  width: 446px;
  height: 468px;
  background: #FAFAFA;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 26px 24px 0;
}
.display-preview-modal .preview-main .main-content .main-right .mb40 {
  margin-bottom: 40px;
}
.display-preview-modal .preview-main .main-content .main-right .title {
  font-size: 18px;
  font-weight: 500;
  color: #4C4C4C;
  line-height: 25px;
  margin-bottom: 12px;
}
.display-preview-modal .preview-main .main-content .main-right .sub-title {
  font-size: 12px;
  font-weight: 400;
  color: #787878;
  line-height: 17px;
}
.display-preview-modal .preview-main .main-content .main-right .main-right-code {
  position: absolute;
  bottom: 32px;
  left: 24px;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  color: #787878;
  font-size: 18px;
}
.display-preview-modal .preview-main .main-content .main-right .main-right-code .code {
  width: 107px;
  height: 107px;
  margin-right: 16px;
}

.display-B-wrap {
  display: flex;
  flex-grow: 0;
  width: 100%;
}
.display-B-wrap .left {
  flex: 1;
  min-width: 0;
  height: 506px;
  background: #FFFFFF;
  border-radius: 4px;
}
.display-B-wrap .left .display-B-left-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  border-bottom: 1px solid #EEEEEE;
  padding: 0 24px;
}
.display-B-wrap .left .display-B-left-header .text1 {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}
.display-B-wrap .left .display-B-left-header .text2 {
  font-size: 16px;
  font-weight: 400;
  color: #787878;
  cursor: pointer;
}
.display-B-wrap .left .display-B-left-main-wrapper {
  width: 1600px;
  display: flex;
  flex-shrink: 0;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.4s ease-in-out;
}
.display-B-wrap .left .display-B-left-main {
  position: relative;
  display: flex;
  align-items: center;
  height: 449px;
  box-sizing: border-box;
  margin: 0 28px;
  overflow: hidden;
}
.display-B-wrap .left .display-B-left-main:hover .display-B-left-nav {
  visibility: visible;
}
.display-B-wrap .left .display-B-left-main:hover .display-B-right-nav {
  visibility: visible;
}
.display-B-wrap .left .display-B-left-main .display-B-left-nav {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  font-size: 12px;
  position: absolute;
  color: #787878;
  top: 50%;
  left: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 8px 14px 0px rgba(66, 72, 82, 0.1);
  margin-top: -17.5px;
  z-index: 20;
  cursor: pointer;
}
.display-B-wrap .left .display-B-left-main .display-B-left-nav:hover {
  background: #2C68FF;
  color: #fff;
}
.display-B-wrap .left .display-B-left-main .display-B-right-nav {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transform: rotate(90deg);
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  width: 35px;
  height: 35px;
  margin-top: -17.5px;
  border-radius: 50%;
  background: #2C68FF;
  box-shadow: 1px 8px 14px 0px rgba(66, 72, 82, 0.1);
  z-index: 20;
  cursor: pointer;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 515px;
  height: 288px;
  flex-shrink: 0;
  background: #FAFAFA;
  border-radius: 16px;
  margin-right: 20px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-img {
  position: absolute;
  width: 184px;
  height: 369px;
  left: 32px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 10px 16px 19px 0px rgba(59, 69, 72, 0.13);
  border-radius: 16px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap {
  margin-left: 232px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap .item-title {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  line-height: 28px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap .item-tag-wrap {
  display: flex;
  margin: 8px 0 16px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap .item-tag-wrap .item-tag {
  padding: 5px 8px;
  height: 26px;
  background: rgba(54, 198, 38, 0.1);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #36C626;
  line-height: 17px;
  margin-right: 8px;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap .item-tag-wrap .item-tag:last-child {
  margin-right: 0;
}
.display-B-wrap .left .display-B-left-main .display-B-left-item .item-wrap .item-desc {
  width: 264px;
  min-height: 51px;
  font-size: 12px;
  font-weight: 400;
  color: #787878;
  line-height: 17px;
  margin-bottom: 30px;
}
.display-B-wrap .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 282px;
  height: 506px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-left: 24px;
  padding-top: 96px;
}
.display-B-wrap .right .text1 {
  height: 46px;
  font-size: 33px;
  color: #33373D;
  line-height: 46px;
  margin-top: 24px;
}
.display-B-wrap .right .text2 {
  height: 28px;
  font-size: 20px;
  color: #787878;
  line-height: 28px;
  margin: 8px 0 24px;
}

.fx-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #EEEEEE;
  padding: 0 24px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.fx-right-header-menu {
  flex: 1;
  height: 343px;
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rightA {
  width: 282px;
  background-color: #fff;
  height: 343px;
  margin-left: 24px;
  border-radius: 4px;
}
.rightA .rightA-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  border-bottom: 1px solid #EEEEEE;
  padding: 0 24px;
}
.rightA .rightA-header .text1 {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
}
.rightA .rightA-header .text2 {
  font-size: 16px;
  font-weight: 400;
  color: #787878;
  cursor: pointer;
}
.rightA .rightA-main {
  height: 272px;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 16px 24px;
}
.rightA .rightA-main .rightA-main-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  line-height: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}
.rightA .rightA-main .rightA-main-item .new {
  width: 45px;
  height: 20px;
  text-align: center;
  background: rgba(255, 113, 44, 0.1);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #FF712C;
  line-height: 20px;
  margin-right: 8px;
}

.fx-right-header {
  display: flex;
  margin-bottom: 16px;
}

.fx-menu-wrap {
  display: flex;
  align-items: center;
  height: 54px;
  background-color: #ffffff;
}
.fx-menu-wrap .fx-menu-item {
  position: relative;
  height: 24px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #262626;
  margin-right: 24px;
}
.fx-menu-wrap .fx-menu-item:hover {
  cursor: pointer;
  color: #2C68FF;
}
.fx-menu-wrap .fx-menu-item .fx-menu-item-dot {
  position: absolute;
  left: 0;
  bottom: -16px;
  width: 0;
  height: 0;
  background-color: #2C68FF;
  transition: all 0.1s ease;
}
.fx-menu-wrap .fx-menu-item--active {
  color: #2C68FF;
}
.fx-menu-wrap .fx-menu-item--active .fx-menu-item-dot {
  width: 100%;
  height: 2px;
}

.fx-update-time {
  padding-right: 14px;
  padding-top: 14px;
  margin-left: auto;
  line-height: 24px;
  text-align: right;
  color: #d9d9d9;
  background-color: #ffffff;
}

.fx-menu-item-container {
  border-radius: 2px;
  background-color: #ffffff;
}

.fx-break-line-wrap {
  height: 55%;
  padding: 26px 16px 16px 24px;
}

.pie-chart-table-wrap {
  display: flex;
  margin: 0 32px;
}
.pie-chart-table-wrap .menu-pie-wrap .pie-wrap {
  position: relative;
}
.pie-chart-table-wrap .menu-pie-wrap .antd-menu-wrap {
  line-height: 32px;
  display: flex;
}
.pie-chart-table-wrap .menu-pie-wrap .ant-menu-horizontal {
  border-bottom: none;
  border-radius: 4px;
  line-height: 32px;
}
.pie-chart-table-wrap .menu-pie-wrap .ant-menu-horizontal .ant-menu-item {
  width: 60px;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
}
.pie-chart-table-wrap .menu-pie-wrap .ant-menu-horizontal .ant-menu-item-selected, .pie-chart-table-wrap .menu-pie-wrap .ant-menu-horizontal .ant-menu-item:hover {
  border: 1px solid #2C68FF;
  color: #2C68FF;
}
.pie-chart-table-wrap .table-wrap {
  margin-left: 32px;
  overflow: auto;
  flex: 1;
}

.order-quantity-table {
  margin-top: 16px;
  border-radius: 4px;
  padding: 16px 24px 24px;
  background-color: #ffffff;
}

.radius-red {
  background-color: #F2637B;
}

.radius-bule {
  background-color: #3AA0FF;
}

.radius-green {
  background-color: #00C800;
}

.radius-wrap {
  display: flex;
}
.radius-wrap .radius, .radius-wrap .radius-red, .radius-wrap .radius-bule, .radius-wrap .radius-green {
  width: 18px;
  height: 18px;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: white;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
}
.radius-wrap .no-radius {
  font-size: 13px;
  margin-left: 5px;
  margin-right: 8px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  text-indent: 1px;
}

.name1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.care-wrapper {
  display: flex;
  justify-content: space-around;
}
.care-wrapper .care {
  cursor: pointer;
  padding: 10px;
}

.circle-wrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.circle {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
  background: red;
}

.question {
  cursor: pointer;
  margin-left: 4px;
}

.pie {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background: #0090FF;
}

.fx-rightB-content {
  background: #FFFFFF;
  border-radius: 4px;
  margin-bottom: 16px;
}
.fx-rightB-content .fx-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 24px;
  border-bottom: 1px solid #EEEEEE;
}
.fx-rightB-content .fx-content-header .fx-date-menu {
  display: flex;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item {
  position: relative;
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border: 1px solid #D9D9D9;
  color: #4C4C4C;
  font-size: 14px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item:nth-child(2) {
  margin-left: -1px;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item:last-child {
  margin-left: -1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item:hover {
  color: #2C68FF;
  border-color: #2C68FF;
  z-index: 2;
}
.fx-rightB-content .fx-content-header .fx-date-menu .fx-date-menu-item-active {
  color: #2C68FF;
  border-color: #2C68FF;
  z-index: 2;
}
.fx-rightB-content .fx-content-header .sort-button {
  display: flex;
  font-size: 16px;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn {
  position: relative;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn:last-child {
  margin-left: 40px;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn:hover {
  cursor: pointer;
  color: #2C68FF;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn .sort-btn-dot {
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  bottom: -18.5px;
  background-color: #2C68FF;
  transition: all 0.2s ease;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn--active {
  color: #2C68FF;
}
.fx-rightB-content .fx-content-header .sort-button .sort-btn--active .sort-btn-dot {
  width: 100%;
  height: 2px;
}
.fx-rightB-content .fx-content-main {
  display: flex;
  box-sizing: border-box;
  padding: 50px 24px 0 24px;
}
.fx-rightB-content .fx-content-main .pie-wrap {
  display: block;
  position: relative;
  width: 250px;
}
.fx-rightB-content .fx-content-main .pie-list {
  flex: 1;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-nodata {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #262626;
  margin-left: -250px;
  padding-bottom: 60px;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 22px;
  line-height: 22px;
  font-size: 14px;
  color: rgba(76, 76, 76, 0.65);
  margin-bottom: 16px;
  padding-left: 6%;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item:last-child {
  margin-bottom: 16px;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .pie-list-item-left {
  display: flex;
  align-items: center;
  width: 75%;
  overflow: hidden;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .pie-list-item-left .pie-list-item-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .pie-list-item-left .pie-list-item-name {
  flex: 0 0 90%;
  width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 7px;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .pie-list-item-right {
  display: flex;
  width: 17%;
  justify-content: space-between;
  align-items: center;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .mr48 {
  padding-left: 8px;
  border-left: 1px solid #D9D9D9;
}
.fx-rightB-content .fx-content-main .pie-list .pie-list-item .uv {
  color: #4C4C4C;
}