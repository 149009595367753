.search_form {
  border-radius: 4px;
  position: relative;
  background: #fff;
  padding: 20px;
}
.search_form .ant-form-item {
  margin-bottom: 16px;
}
.search_form .ant-radio-wrapper {
  display: inline-block;
}
.search_form .order-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search_form .order-btn .search-btn {
  cursor: pointer;
  color: #2C68FF;
  margin-left: 8px;
}